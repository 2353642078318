export const petTypeOptions = [
    { key: 'Birds', value: 'Birds' },
    { key: 'Cats', value: 'Cats' },
    { key: 'Dogs', value: 'Dogs' },
    { key: 'Miscellaneous', value: 'Miscellaneous' },
];

export const dogsOptions = [
    { key: 'Afador', value: 'Afador' },
    { key: 'Affenhuahua', value: 'Affenhuahua' },
    { key: 'Affenpinscher', value: 'Affenpinscher' },
    { key: 'Airedale Terrier', value: 'Airedale Terrier' },
    { key: 'Akbash', value: 'Akbash' },
    { key: 'Akita', value: 'Akita' },
    { key: 'Akita Chow', value: 'Akita Chow' },
    { key: 'Akita Pit', value: 'Akita Pit' },
    { key: 'Akita Shepherd', value: 'Akita Shepherd' },
    { key: 'Alaskan Klee Kai', value: 'Alaskan Klee Kai' },
    { key: 'Alaskan Malamute', value: 'Alaskan Malamute' },
    { key: 'American Bulldog', value: 'American Bulldog' },
    { key: 'American English Coonhound', value: 'American English Coonhound' },
    { key: 'American Eskimo Dog', value: 'American Eskimo Dog' },
    { key: 'American Foxhound', value: 'American Foxhound' },
    { key: 'American Hairless Terrier', value: 'American Hairless Terrier' },
    { key: 'American Leopard Hound', value: 'American Leopard Hound' },
    { key: 'American Pit Bull Terrier', value: 'American Pit Bull Terrier' },
    { key: 'American Pugabull', value: 'American Pugabull' },
    { key: 'American Staffordshire Terrier', value: 'American Staffordshire Terrier' },
    { key: 'American Water Spaniel', value: 'American Water Spaniel' },
    { key: 'Anatolian Shepherd Dog', value: 'Anatolian Shepherd Dog' },
    { key: 'Appenzeller Sennenhunde', value: 'Appenzeller Sennenhunde' },
    { key: 'Auggie', value: 'Auggie' },
    { key: 'Aussiedoodle', value: 'Aussiedoodle' },
    { key: 'Aussiepom', value: 'Aussiepom' },
    { key: 'Australian Cattle Dog', value: 'Australian Cattle Dog' },
    { key: 'Australian Kelpie', value: 'Australian Kelpie' },
    { key: 'Australian Retriever', value: 'Australian Retriever' },
    { key: 'Australian Shepherd', value: 'Australian Shepherd' },
    { key: 'Australian Shepherd Husky', value: 'Australian Shepherd Husky' },
    { key: 'Australian Shepherd Lab Mix', value: 'Australian Shepherd Lab Mix' },
    { key: 'Australian Shepherd Pit Bull Mix', value: 'Australian Shepherd Pit Bull Mix' },
    { key: 'Australian Stumpy Tail Cattle Dog', value: 'Australian Stumpy Tail Cattle Dog' },
    { key: 'Australian Terrier', value: 'Australian Terrier' },
    { key: 'Barbet', value: 'Barbet' },
    { key: 'Basenji', value: 'Basenji' },
    { key: 'Bassador', value: 'Bassador' },
    { key: 'Basset Fauve de Bretagne', value: 'Basset Fauve de Bretagne' },
    { key: 'Basset Hound', value: 'Basset Hound' },
    { key: 'Basset Retriever', value: 'Basset Retriever' },
    { key: 'Bavarian Mountain Scent Hound', value: 'Bavarian Mountain Scent Hound' },
    { key: 'Beabull', value: 'Beabull' },
    { key: 'Beagle', value: 'Beagle' },
    { key: 'Beaglier', value: 'Beaglier' },
    { key: 'Bearded Collie', value: 'Bearded Collie' },
    { key: 'Bedlington Terrier', value: 'Bedlington Terrier' },
    { key: 'Belgian Malinois', value: 'Belgian Malinois' },
    { key: 'Belgian Sheepdog', value: 'Belgian Sheepdog' },
    { key: 'Belgian Tervuren', value: 'Belgian Tervuren' },
    { key: 'Bergamasco Sheepdog', value: 'Bergamasco Sheepdog' },
    { key: 'Berger Picard', value: 'Berger Picard' },
    { key: 'Bernedoodle', value: 'Bernedoodle' },
    { key: 'Bernese Mountain Dog', value: 'Bernese Mountain Dog' },
    { key: 'Bichon Frise', value: 'Bichon Frise' },
    { key: 'Biewer Terrier', value: 'Biewer Terrier' },
    { key: 'Black and Tan Coonhound', value: 'Black and Tan Coonhound' },
    { key: 'Black Mouth Cur', value: 'Black Mouth Cur' },
    { key: 'Black Russian Terrier', value: 'Black Russian Terrier' },
    { key: 'Bloodhound', value: 'Bloodhound' },
    { key: 'Blue Lacy', value: 'Blue Lacy' },
    { key: 'Bluetick Coonhound', value: 'Bluetick Coonhound' },
    { key: 'Bocker', value: 'Bocker' },
    { key: 'Boerboel', value: 'Boerboel' },
    { key: 'Boglen Terrier', value: 'Boglen Terrier' },
    { key: 'Bohemian Shepherd', value: 'Bohemian Shepherd' },
    { key: 'Bolognese', value: 'Bolognese' },
    { key: 'Borador', value: 'Borador' },
    { key: 'Border Collie', value: 'Border Collie' },
    { key: 'Border Sheepdog', value: 'Border Sheepdog' },
    { key: 'Border Terrier', value: 'Border Terrier' },
    { key: 'Bordoodle', value: 'Bordoodle' },
    { key: 'Borzoi', value: 'Borzoi' },
    { key: 'BoShih', value: 'BoShih' },
    { key: 'Bossie', value: 'Bossie' },
    { key: 'Boston Boxer', value: 'Boston Boxer' },
    { key: 'Boston Terrier', value: 'Boston Terrier' },
    { key: 'Boston Terrier Pekingese Mix', value: 'Boston Terrier Pekingese Mix' },
    { key: 'Bouvier des Flandres', value: 'Bouvier des Flandres' },
    { key: 'Boxador', value: 'Boxador' },
    { key: 'Boxer', value: 'Boxer' },
    { key: 'Boxerdoodle', value: 'Boxerdoodle' },
    { key: 'Boxmatian', value: 'Boxmatian' },
    { key: 'Boxweiler', value: 'Boxweiler' },
    { key: 'Boykin Spaniel', value: 'Boykin Spaniel' },
    { key: 'Bracco Italiano', value: 'Bracco Italiano' },
    { key: 'Braque du Bourbonnais', value: 'Braque du Bourbonnais' },
    { key: 'Briard', value: 'Briard' },
    { key: 'Broholmer', value: 'Broholmer' },
    { key: 'Brussels Griffon', value: 'Brussels Griffon' },
    { key: 'Bugg', value: 'Bugg' },
    { key: 'Bull-Pei', value: 'Bull-Pei' },
    { key: 'Bull Terrier', value: 'Bull Terrier' },
    { key: 'Bullador', value: 'Bullador' },
    { key: 'Bullboxer Pit', value: 'Bullboxer Pit' },
    { key: 'Bulldog', value: 'Bulldog' },
    { key: 'Bullmastiff', value: 'Bullmastiff' },
    { key: 'Bullmatian', value: 'Bullmatian' },
    { key: 'Cairn Terrier', value: 'Cairn Terrier' },
    { key: 'Canaan Dog', value: 'Canaan Dog' },
    { key: 'Cane Corso', value: 'Cane Corso' },
    { key: 'Cardigan Welsh Corgi', value: 'Cardigan Welsh Corgi' },
    { key: 'Carolina Dog', value: 'Carolina Dog' },
    { key: 'Catahoula Bulldog', value: 'Catahoula Bulldog' },
    { key: 'Catahoula Leopard Dog', value: 'Catahoula Leopard Dog' },
    { key: 'Caucasian Shepherd Dog', value: 'Caucasian Shepherd Dog' },
    { key: 'Cav-a-Jack', value: 'Cav-a-Jack' },
    { key: 'Cavachon', value: 'Cavachon' },
    { key: 'Cavador', value: 'Cavador' },
    { key: 'Cavalier King Charles Spaniel', value: 'Cavalier King Charles Spaniel' },
    { key: 'Cavapoo', value: 'Cavapoo' },
    { key: 'Central Asian Shepherd Dog', value: 'Central Asian Shepherd Dog' },
    { key: 'Cesky Terrier', value: 'Cesky Terrier' },
    { key: 'Chabrador', value: 'Chabrador' },
    { key: 'Cheagle', value: 'Cheagle' },
    { key: 'Chesapeake Bay Retriever', value: 'Chesapeake Bay Retriever' },
    { key: 'Chi Chi', value: 'Chi Chi' },
    { key: 'Chi-Poo', value: 'Chi-Poo' },
    { key: 'Chigi', value: 'Chigi' },
    { key: 'Chihuahua', value: 'Chihuahua' },
    { key: 'Chilier', value: 'Chilier' },
    { key: 'Chinese Crested', value: 'Chinese Crested' },
    { key: 'Chinese Shar-Pei', value: 'Chinese Shar-Pei' },
    { key: 'Chinook', value: 'Chinook' },
    { key: 'Chion', value: 'Chion' },
    { key: 'Chipin', value: 'Chipin' },
    { key: 'Chiweenie', value: 'Chiweenie' },
    { key: 'Chorkie', value: 'Chorkie' },
    { key: 'Chow Chow', value: 'Chow Chow' },
    { key: 'Chow Shepherd', value: 'Chow Shepherd' },
    { key: 'Chug', value: 'Chug' },
    { key: 'Chusky', value: 'Chusky' },
    { key: 'Cirneco dell’Etna', value: 'Cirneco dell’Etna' },
    { key: 'Clumber Spaniel', value: 'Clumber Spaniel' },
    { key: 'Cockalier', value: 'Cockalier' },
    { key: 'Cockapoo', value: 'Cockapoo' },
    { key: 'Cocker Spaniel', value: 'Cocker Spaniel' },
    { key: 'Collie', value: 'Collie' },
    { key: 'Corgi Inu', value: 'Corgi Inu' },
    { key: 'Corgidor', value: 'Corgidor' },
    { key: 'Corman Shepherd', value: 'Corman Shepherd' },
    { key: 'Coton de Tulear', value: 'Coton de Tulear' },
    { key: 'Croatian Sheepdog', value: 'Croatian Sheepdog' },
    { key: 'Curly-Coated Retriever', value: 'Curly-Coated Retriever' },
    { key: 'Dachsador', value: 'Dachsador' },
    { key: 'Dachshund', value: 'Dachshund' },
    { key: 'Dalmatian', value: 'Dalmatian' },
    { key: 'Dandie Dinmont Terrier', value: 'Dandie Dinmont Terrier' },
    { key: 'Daniff', value: 'Daniff' },
    { key: 'Danish-Swedish Farmdog', value: 'Danish-Swedish Farmdog' },
    { key: 'Deutscher Wachtelhund', value: 'Deutscher Wachtelhund' },
    { key: 'Doberdor', value: 'Doberdor' },
    { key: 'Doberman Pinscher', value: 'Doberman Pinscher' },
    { key: 'Docker', value: 'Docker' },
    { key: 'Dogo Argentino', value: 'Dogo Argentino' },
    { key: 'Dogue de Bordeaux', value: 'Dogue de Bordeaux' },
    { key: 'Dorgi', value: 'Dorgi' },
    { key: 'Dorkie', value: 'Dorkie' },
    { key: 'Doxiepoo', value: 'Doxiepoo' },
    { key: 'Doxle', value: 'Doxle' },
    { key: 'Drentsche Patrijshond', value: 'Drentsche Patrijshond' },
    { key: 'Drever', value: 'Drever' },
    { key: 'Dutch Shepherd', value: 'Dutch Shepherd' },
    { key: 'English Cocker Spaniel', value: 'English Cocker Spaniel' },
    { key: 'English Foxhound', value: 'English Foxhound' },
    { key: 'English Setter', value: 'English Setter' },
    { key: 'English Springer Spaniel', value: 'English Springer Spaniel' },
    { key: 'English Toy Spaniel', value: 'English Toy Spaniel' },
    { key: 'Entlebucher Mountain Dog', value: 'Entlebucher Mountain Dog' },
    { key: 'Estrela Mountain Dog', value: 'Estrela Mountain Dog' },
    { key: 'Eurasier', value: 'Eurasier' },
    { key: 'Field Spaniel', value: 'Field Spaniel' },
    { key: 'Fila Brasileiro', value: 'Fila Brasileiro' },
    { key: 'Finnish Lapphund', value: 'Finnish Lapphund' },
    { key: 'Finnish Spitz', value: 'Finnish Spitz' },
    { key: 'Flat-Coated Retriever', value: 'Flat-Coated Retriever' },
    { key: 'Fox Terrier', value: 'Fox Terrier' },
    { key: 'French Bulldog', value: 'French Bulldog' },
    { key: 'French Bullhuahua', value: 'French Bullhuahua' },
    { key: 'French Spaniel', value: 'French Spaniel' },
    { key: 'Frenchton', value: 'Frenchton' },
    { key: 'Frengle', value: 'Frengle' },
    { key: 'German Longhaired Pointer', value: 'German Longhaired Pointer' },
    { key: 'German Pinscher', value: 'German Pinscher' },
    { key: 'German Shepherd Dog', value: 'German Shepherd Dog' },
    { key: 'German Shepherd Pit Bull', value: 'German Shepherd Pit Bull' },
    { key: 'German Shepherd Rottweiler Mix', value: 'German Shepherd Rottweiler Mix' },
    { key: 'German Sheprador', value: 'German Sheprador' },
    { key: 'German Shorthaired Pointer', value: 'German Shorthaired Pointer' },
    { key: 'German Spitz', value: 'German Spitz' },
    { key: 'German Wirehaired Pointer', value: 'German Wirehaired Pointer' },
    { key: 'Giant Schnauzer', value: 'Giant Schnauzer' },
    { key: 'Glen of Imaal Terrier', value: 'Glen of Imaal Terrier' },
    { key: 'Goberian', value: 'Goberian' },
    { key: 'Goldador', value: 'Goldador' },
    { key: 'Golden Cocker Retriever', value: 'Golden Cocker Retriever' },
    { key: 'Golden Mountain Dog', value: 'Golden Mountain Dog' },
    { key: 'Golden Retriever', value: 'Golden Retriever' },
    { key: 'Golden Retriever Corgi', value: 'Golden Retriever Corgi' },
    { key: 'Golden Shepherd', value: 'Golden Shepherd' },
    { key: 'Goldendoodle', value: 'Goldendoodle' },
    { key: 'Gollie', value: 'Gollie' },
    { key: 'Gordon Setter', value: 'Gordon Setter' },
    { key: 'Great Dane', value: 'Great Dane' },
    { key: 'Great Pyrenees', value: 'Great Pyrenees' },
    { key: 'Greater Swiss Mountain Dog', value: 'Greater Swiss Mountain Dog' },
    { key: 'Greyador', value: 'Greyador' },
    { key: 'Greyhound', value: 'Greyhound' },
    { key: 'Hamiltonstovare', value: 'Hamiltonstovare' },
    { key: 'Hanoverian Scenthound', value: 'Hanoverian Scenthound' },
    { key: 'Harrier', value: 'Harrier' },
    { key: 'Havanese', value: 'Havanese' },
    { key: 'Hokkaido', value: 'Hokkaido' },
    { key: 'Horgi', value: 'Horgi' },
    { key: 'Hovawart', value: 'Hovawart' },
    { key: 'Huskita', value: 'Huskita' },
    { key: 'Huskydoodle', value: 'Huskydoodle' },
    { key: 'Ibizan Hound', value: 'Ibizan Hound' },
    { key: 'Icelandic Sheepdog', value: 'Icelandic Sheepdog' },
    { key: 'Irish Red And White Setter', value: 'Irish Red And White Setter' },
    { key: 'Irish Setter', value: 'Irish Setter' },
    { key: 'Irish Terrier', value: 'Irish Terrier' },
    { key: 'Irish Water Spaniel', value: 'Irish Water Spaniel' },
    { key: 'Irish Wolfhound', value: 'Irish Wolfhound' },
    { key: 'Italian Greyhound', value: 'Italian Greyhound' },
    { key: 'Jack-A-Poo', value: 'Jack-A-Poo' },
    { key: 'Jack Chi', value: 'Jack Chi' },
    { key: 'Jack Russell Terrier', value: 'Jack Russell Terrier' },
    { key: 'Jackshund', value: 'Jackshund' },
    { key: 'Japanese Chin', value: 'Japanese Chin' },
    { key: 'Japanese Spitz', value: 'Japanese Spitz' },
    { key: 'Korean Jindo Dog', value: 'Korean Jindo Dog' },
    { key: 'Kai Ken', value: 'Kai Ken' },
    { key: 'Karelian Bear Dog', value: 'Karelian Bear Dog' },
    { key: 'Keeshond', value: 'Keeshond' },
    { key: 'Kerry Blue Terrier', value: 'Kerry Blue Terrier' },
    { key: 'King Shepherd', value: 'King Shepherd' },
    { key: 'Kishu Ken', value: 'Kishu Ken' },
    { key: 'Komondor', value: 'Komondor' },
    { key: 'Kooikerhondje', value: 'Kooikerhondje' },
    { key: 'Kuvasz', value: 'Kuvasz' },
    { key: 'Kyi-Leo', value: 'Kyi-Leo' },
    { key: 'Lab Pointer', value: 'Lab Pointer' },
    { key: 'Labernese', value: 'Labernese' },
    { key: 'Labmaraner', value: 'Labmaraner' },
    { key: 'Labrabull', value: 'Labrabull' },
    { key: 'Labradane', value: 'Labradane' },
    { key: 'Labradoodle', value: 'Labradoodle' },
    { key: 'Labrador Retriever', value: 'Labrador Retriever' },
    { key: 'Labrastaff', value: 'Labrastaff' },
    { key: 'Labsky', value: 'Labsky' },
    { key: 'Lagotto Romagnolo', value: 'Lagotto Romagnolo' },
    { key: 'Lakeland Terrier', value: 'Lakeland Terrier' },
    { key: 'Lancashire Heeler', value: 'Lancashire Heeler' },
    { key: 'Leonberger', value: 'Leonberger' },
    { key: 'Lhasa Apso', value: 'Lhasa Apso' },
    { key: 'Lhasapoo', value: 'Lhasapoo' },
    { key: 'Lowchen', value: 'Lowchen' },
    { key: 'Maltese', value: 'Maltese' },
    { key: 'Maltese Shih Tzu', value: 'Maltese Shih Tzu' },
    { key: 'Maltipoo', value: 'Maltipoo' },
    { key: 'Manchester Terrier', value: 'Manchester Terrier' },
    { key: 'Mastador', value: 'Mastador' },
    { key: 'Mastiff', value: 'Mastiff' },
    { key: 'Miniature Pinscher', value: 'Miniature Pinscher' },
    { key: 'Miniature Schnauzer', value: 'Miniature Schnauzer' },
    { key: 'Morkie', value: 'Morkie' },
    { key: 'Mudi', value: 'Mudi' },
    { key: 'Mutt (Mixed)', value: 'Mutt (Mixed)' },
    { key: 'Neapolitan Mastiff', value: 'Neapolitan Mastiff' },
    { key: 'Newfoundland', value: 'Newfoundland' },
    { key: 'Norfolk Terrier', value: 'Norfolk Terrier' },
    { key: 'Northern Inuit Dog', value: 'Northern Inuit Dog' },
    { key: 'Norwegian Buhund', value: 'Norwegian Buhund' },
    { key: 'Norwegian Elkhound', value: 'Norwegian Elkhound' },
    { key: 'Norwegian Lundehund', value: 'Norwegian Lundehund' },
    { key: 'Norwich Terrier', value: 'Norwich Terrier' },
    { key: 'Nova Scotia Duck Tolling Retriever', value: 'Nova Scotia Duck Tolling Retriever' },
    { key: 'Old English Sheepdog', value: 'Old English Sheepdog' },
    { key: 'Otterhound', value: 'Otterhound' },
    { key: 'Papillon', value: 'Papillon' },
    { key: 'Papipoo', value: 'Papipoo' },
    { key: 'Patterdale Terrier', value: 'Patterdale Terrier' },
    { key: 'Peekapoo', value: 'Peekapoo' },
    { key: 'Pekingese', value: 'Pekingese' },
    { key: 'Pembroke Welsh Corgi', value: 'Pembroke Welsh Corgi' },
    { key: 'Petit Basset Griffon Vendéen', value: 'Petit Basset Griffon Vendéen' },
    { key: 'Pharaoh Hound', value: 'Pharaoh Hound' },
    { key: 'Pitsky', value: 'Pitsky' },
    { key: 'Plott', value: 'Plott' },
    { key: 'Pocket Beagle', value: 'Pocket Beagle' },
    { key: 'Pointer', value: 'Pointer' },
    { key: 'Polish Lowland Sheepdog', value: 'Polish Lowland Sheepdog' },
    { key: 'Pomapoo', value: 'Pomapoo' },
    { key: 'Pomchi', value: 'Pomchi' },
    { key: 'Pomeagle', value: 'Pomeagle' },
    { key: 'Pomeranian', value: 'Pomeranian' },
    { key: 'Pomsky', value: 'Pomsky' },
    { key: 'Poochon', value: 'Poochon' },
    { key: 'Poodle', value: 'Poodle' },
    { key: 'Portuguese Podengo Pequeno', value: 'Portuguese Podengo Pequeno' },
    { key: 'Portuguese Sheepdog', value: 'Portuguese Sheepdog' },
    { key: 'Portuguese Water Dog', value: 'Portuguese Water Dog' },
    { key: 'Pug', value: 'Pug' },
    { key: 'Pugalier', value: 'Pugalier' },
    { key: 'Puggle', value: 'Puggle' },
    { key: 'Puginese', value: 'Puginese' },
    { key: 'Puli', value: 'Puli' },
    { key: 'Pyredoodle', value: 'Pyredoodle' },
    { key: 'Pyrenean Mastiff', value: 'Pyrenean Mastiff' },
    { key: 'Pyrenean Shepherd', value: 'Pyrenean Shepherd' },
    { key: 'Rat Terrier', value: 'Rat Terrier' },
    { key: 'Redbone Coonhound', value: 'Redbone Coonhound' },
    { key: 'Rhodesian Ridgeback', value: 'Rhodesian Ridgeback' },
    { key: 'Rottador', value: 'Rottador' },
    { key: 'Rottle', value: 'Rottle' },
    { key: 'Rottweiler', value: 'Rottweiler' },
    { key: 'Saint Berdoodle', value: 'Saint Berdoodle' },
    { key: 'Saint Bernard', value: 'Saint Bernard' },
    { key: 'Saluki', value: 'Saluki' },
    { key: 'Samoyed', value: 'Samoyed' },
    { key: 'Samusky', value: 'Samusky' },
    { key: 'Schipperke', value: 'Schipperke' },
    { key: 'Schnoodle', value: 'Schnoodle' },
    { key: 'Scottish Deerhound', value: 'Scottish Deerhound' },
    { key: 'Scottish Terrier', value: 'Scottish Terrier' },
    { key: 'Sealyham Terrier', value: 'Sealyham Terrier' },
    { key: 'Sheepadoodle', value: 'Sheepadoodle' },
    { key: 'Shepsky', value: 'Shepsky' },
    { key: 'Shetland Sheepdog', value: 'Shetland Sheepdog' },
    { key: 'Shiba Inu', value: 'Shiba Inu' },
    { key: 'Shichon', value: 'Shichon' },
    { key: 'Shih-Poo', value: 'Shih-Poo' },
    { key: 'Shih Tzu', value: 'Shih Tzu' },
    { key: 'Shiloh Shepherd', value: 'Shiloh Shepherd' },
    { key: 'Shiranian', value: 'Shiranian' },
    { key: 'Shollie', value: 'Shollie' },
    { key: 'Shorkie', value: 'Shorkie' },
    { key: 'Siberian Husky', value: 'Siberian Husky' },
    { key: 'Silken Windhound', value: 'Silken Windhound' },
    { key: 'Silky Terrier', value: 'Silky Terrier' },
    { key: 'Skye Terrier', value: 'Skye Terrier' },
    { key: 'Sloughi', value: 'Sloughi' },
    { key: 'Small Munsterlander Pointer', value: 'Small Munsterlander Pointer' },
    { key: 'Soft Coated Wheaten Terrier', value: 'Soft Coated Wheaten Terrier' },
    { key: 'Spanish Mastiff', value: 'Spanish Mastiff' },
    { key: 'Spinone Italiano', value: 'Spinone Italiano' },
    { key: 'Springador', value: 'Springador' },
    { key: 'Stabyhoun', value: 'Stabyhoun' },
    { key: 'Staffordshire Bull Terrier', value: 'Staffordshire Bull Terrier' },
    { key: 'Staffy Bull Bullmastiff', value: 'Staffy Bull Bullmastiff' },
    { key: 'Standard Schnauzer', value: 'Standard Schnauzer' },
    { key: 'Sussex Spaniel', value: 'Sussex Spaniel' },
    { key: 'Swedish Lapphund', value: 'Swedish Lapphund' },
    { key: 'Swedish Vallhund', value: 'Swedish Vallhund' },
    { key: 'Taiwan Dog', value: 'Taiwan Dog' },
    { key: 'Terripoo', value: 'Terripoo' },
    { key: 'Texas Heeler', value: 'Texas Heeler' },
    { key: 'Tibetan Mastiff', value: 'Tibetan Mastiff' },
    { key: 'Tibetan Spaniel', value: 'Tibetan Spaniel' },
    { key: 'Tibetan Terrier', value: 'Tibetan Terrier' },
    { key: 'Toy Fox Terrier', value: 'Toy Fox Terrier' },
    { key: 'Transylvanian Hound', value: 'Transylvanian Hound' },
    { key: 'Treeing Tennessee Brindle', value: 'Treeing Tennessee Brindle' },
    { key: 'Treeing Walker Coonhound', value: 'Treeing Walker Coonhound' },
    { key: 'Valley Bulldog', value: 'Valley Bulldog' },
    { key: 'Vizsla', value: 'Vizsla' },
    { key: 'Weimaraner', value: 'Weimaraner' },
    { key: 'Welsh Springer Spaniel', value: 'Welsh Springer Spaniel' },
    { key: 'Welsh Terrier', value: 'Welsh Terrier' },
    { key: 'West Highland White Terrier', value: 'West Highland White Terrier' },
    { key: 'Westiepoo', value: 'Westiepoo' },
    { key: 'Whippet', value: 'Whippet' },
    { key: 'Whoodle', value: 'Whoodle' },
    { key: 'Wirehaired Pointing Griffon', value: 'Wirehaired Pointing Griffon' },
    { key: 'Xoloitzcuintli', value: 'Xoloitzcuintli' },
    { key: 'Yorkipoo', value: 'Yorkipoo' },
    { key: 'Yorkshire Terrier', value: 'Yorkshire Terrier' },
];

export const catsOptions = [
    { key: 'Abyssinian', value: 'Abyssinian' },
    { key: 'American Bobtail', value: 'American Bobtail' },
    { key: 'American Curl', value: 'American Curl' },
    { key: 'American Shorthair', value: 'American Shorthair' },
    { key: 'American Wirehair', value: 'American Wirehair' },
    { key: 'Applehead Siamese', value: 'Applehead Siamese' },
    { key: 'Bengal', value: 'Bengal' },
    { key: 'Birman', value: 'Birman' },
    { key: 'Bombay', value: 'Bombay' },
    { key: 'British Shorthair', value: 'British Shorthair' },
    { key: 'Burmese', value: 'Burmese' },
    { key: 'Burmilla', value: 'Burmilla' },
    { key: 'Calico', value: 'Calico' },
    { key: 'Canadian Hairless', value: 'Canadian Hairless' },
    { key: 'Chartreux', value: 'Chartreux' },
    { key: 'Chausie', value: 'Chausie' },
    { key: 'Chinchilla', value: 'Chinchilla' },
    { key: 'Cornish Rex', value: 'Cornish Rex' },
    { key: 'Cymric', value: 'Cymric' },
    { key: 'Devon Rex', value: 'Devon Rex' },
    { key: 'Dilute Calico', value: 'Dilute Calico' },
    { key: 'Dilute Tortoiseshell', value: 'Dilute Tortoiseshell' },
    { key: 'Domestic Long Hair', value: 'Domestic Long Hair' },
    { key: 'Domestic Medium Hair', value: 'Domestic Medium Hair' },
    { key: 'Domestic Short Hair', value: 'Domestic Short Hair' },
    { key: 'Egyptian Mau', value: 'Egyptian Mau' },
    { key: 'Exotic Shorthair', value: 'Exotic Shorthair' },
    { key: 'Extra-Toes Cat - Hemingway Polydactyl', value: 'Extra-Toes Cat - Hemingway Polydactyl' },
    { key: 'Havana', value: 'Havana' },
    { key: 'Himalayan', value: 'Himalayan' },
    { key: 'Japanese Bobtail', value: 'Japanese Bobtail' },
    { key: 'Javanese', value: 'Javanese' },
    { key: 'Korat', value: 'Korat' },
    { key: 'LaPerm', value: 'LaPerm' },
    { key: 'Maine Coon', value: 'Maine Coon' },
    { key: 'Manx', value: 'Manx' },
    { key: 'Munchkin', value: 'Munchkin' },
    { key: 'Nebelung', value: 'Nebelung' },
    { key: 'Norwegian Forest Cat', value: 'Norwegian Forest Cat' },
    { key: 'Ocicat', value: 'Ocicat' },
    { key: 'Oriental Long Hair', value: 'Oriental Long Hair' },
    { key: 'Oriental Short Hair', value: 'Oriental Short Hair' },
    { key: 'Oriental Tabby', value: 'Oriental Tabby' },
    { key: 'Persian', value: 'Persian' },
    { key: 'Pixiebob', value: 'Pixiebob' },
    { key: 'Ragamuffin', value: 'Ragamuffin' },
    { key: 'Ragdoll', value: 'Ragdoll' },
    { key: 'Russian Blue', value: 'Russian Blue' },
    { key: 'Scottish Fold', value: 'Scottish Fold' },
    { key: 'Selkirk Rex', value: 'Selkirk Rex' },
    { key: 'Siamese', value: 'Siamese' },
    { key: 'Siberian', value: 'Siberian' },
    { key: 'Silver', value: 'Silver' },
    { key: 'Singapura', value: 'Singapura' },
    { key: 'Snowshoe', value: 'Snowshoe' },
    { key: 'Somali', value: 'Somali' },
    { key: 'Sphynx - Hairless Cat', value: 'Sphynx - Hairless Cat' },
    { key: 'Tabby', value: 'Tabby' },
    { key: 'Tiger', value: 'Tiger' },
    { key: 'Tonkinese', value: 'Tonkinese' },
    { key: 'Torbie', value: 'Torbie' },
    { key: 'Tortoiseshell', value: 'Tortoiseshell' },
    { key: 'Turkish Angora', value: 'Turkish Angora' },
    { key: 'Turkish Van', value: 'Turkish Van' },
    { key: 'Tuxedo', value: 'Tuxedo' },
    { key: 'York Chocolate', value: 'York Chocolate' },
];

export const birdsOptions = [
    { key: 'African Grey Parrot', value: 'African Grey Parrot' },
    { key: 'Alexandrine Parakeet', value: 'Alexandrine Parakeet' },
    { key: 'Amazon Parrot', value: 'Amazon Parrot' },
    { key: 'Blue-and-Gold Macaw', value: 'Blue-and-Gold Macaw' },
    { key: 'Blue-Crowned Conure', value: 'Blue-Crowned Conure' },
    { key: 'Blue-Headed Pionus', value: 'Blue-Headed Pionus' },
    { key: 'Budgie (Parakeet)', value: 'Budgie (Parakeet)' },
    { key: 'Caique', value: 'Caique' },
    { key: 'Canary', value: 'Canary' },
    { key: 'Cockatiel', value: 'Cockatiel' },
    { key: 'Cockatoo', value: 'Cockatoo' },
    { key: 'Conure', value: 'Conure' },
    { key: 'Crimson Rosella', value: 'Crimson Rosella' },
    { key: 'Diamond Dove', value: 'Diamond Dove' },
    { key: 'Double Yellow-Headed Amazon Parrot', value: 'Double Yellow-Headed Amazon Parrot' },
    { key: 'Dove', value: 'Dove' },
    { key: 'Eclectus', value: 'Eclectus' },
    { key: 'Finch', value: 'Finch' },
    { key: 'Fischer’s Lovebird', value: 'Fischer’s Lovebird' },
    { key: 'Goffin’s Cockatoo', value: 'Goffin’s Cockatoo' },
    { key: 'Golden-Mantled Rosella', value: 'Golden-Mantled Rosella' },
    { key: 'Gouldian Finch', value: 'Gouldian Finch' },
    { key: 'Green-Cheeked Conure', value: 'Green-Cheeked Conure' },
    { key: 'Green-Winged Macaw', value: 'Green-Winged Macaw' },
    { key: 'Hahn’s Macaw', value: 'Hahn’s Macaw' },
    { key: 'Hyacinth Macaw', value: 'Hyacinth Macaw' },
    { key: 'Indian Ring-Necked Parakeet', value: 'Indian Ring-Necked Parakeet' },
    { key: 'Jenday Conure', value: 'Jenday Conure' },
    { key: 'Lilac-Crowned Amazon', value: 'Lilac-Crowned Amazon' },
    { key: 'Lory', value: 'Lory' },
    { key: 'Lovebird', value: 'Lovebird' },
    { key: 'Macaw', value: 'Macaw' },
    { key: 'Meyer’s Parrot', value: 'Meyer’s Parrot' },
    { key: 'Moluccan Cockatoo', value: 'Moluccan Cockatoo' },
    { key: 'Orange-Winged Amazon Parrot', value: 'Orange-Winged Amazon Parrot' },
    { key: 'Owl Finch', value: 'Owl Finch' },
    { key: 'Pacific Parrotlet', value: 'Pacific Parrotlet' },
    { key: 'Parrot', value: 'Parrot' },
    { key: 'Parrotlet', value: 'Parrotlet' },
    { key: 'Peach-Faced Lovebird', value: 'Peach-Faced Lovebird' },
    { key: 'Pionus Parrot', value: 'Pionus Parrot' },
    { key: 'Poicephalus', value: 'Poicephalus' },
    { key: 'Psittacula', value: 'Psittacula' },
    { key: 'Abyssinian', value: 'Abyssinian' },
    { key: 'Quaker Parakeet', value: 'Quaker Parakeet' },
    { key: 'Rainbow Lory', value: 'Rainbow Lory' },
    { key: 'Red Lory', value: 'Red Lory' },
    { key: 'Red-Factor Canary', value: 'Red-Factor Canary' },
    { key: 'Ring-Necked Dove', value: 'Ring-Necked Dove' },
    { key: 'Rosella', value: 'Rosella' },
    { key: 'Scarlet Macaw', value: 'Scarlet Macaw' },
    { key: 'Senegal Parrot', value: 'Senegal Parrot' },
    { key: 'Song Canary', value: 'Song Canary' },
    { key: 'Sun Conure', value: 'Sun Conure' },
    { key: 'Umbrella Cockatoo', value: 'Umbrella Cockatoo' },
    { key: 'Vasa Parrot', value: 'Vasa Parrot' },
    { key: 'White-Capped Pionus', value: 'White-Capped Pionus' },
    { key: 'Yellow-Naped Amazon Parrot', value: 'Yellow-Naped Amazon Parrot' },
    { key: 'Zebra Finch', value: 'Zebra Finch' },
];

export const miscPetsOptions = [
    { key: 'Ant Farm', value: 'Ant Farm' },
    { key: 'Ball Python', value: 'Ball Python' },
    { key: 'Bearded Dragon', value: 'Bearded Dragon' },
    { key: 'Chinchila', value: 'Chinchila' },
    { key: 'Ferret', value: 'Ferret' },
    { key: 'Fish Tank - Large', value: 'Fish Tank - Large' },
    { key: 'Fish Tank - Small', value: 'Fish Tank - Small' },
    { key: 'Gecko', value: 'Gecko' },
    { key: 'Gerbil', value: 'Gerbil' },
    { key: 'Goldfish', value: 'Goldfish' },
    { key: 'Guinea Pig', value: 'Guinea Pig' },
    { key: 'Flying Squirrel', value: 'Flying Squirrel' },
    { key: 'Hamster', value: 'Hamster' },
    { key: 'Hermit Crabs', value: 'Hermit Crabs' },
    { key: 'Insects', value: 'Insects' },
    { key: 'Lizard - Large', value: 'Lizard - Large' },
    { key: 'Lizard - Small', value: 'Lizard - Small' },
    { key: 'Mice', value: 'Mice' },
    { key: 'Rabbit', value: 'Rabbit' },
    { key: 'Rat', value: 'Rat' },
    { key: 'Snake', value: 'Snake' },
    { key: 'Spider - Other', value: 'Spider - Other' },
    { key: 'Spider - Tarantula', value: 'Spider - Tarantula' },
    { key: 'Sugar Glider', value: 'Sugar Glider' },
    { key: 'Turtle', value: 'Turtle' },
];

export const archiveReasonsOptions = [
    { key: 1, value: 'moved in' },
    { key: 2, value: 'declined' },
    { key: 3, value: 'withdrawn' },
    { key: 4, value: 'referred' },
];

export const bedroomTypes = [
    { key: 1, value: 'any' },
    { key: 2, value: 'studio' },
    { key: 3, value: '1BR' },
    { key: 4, value: '2BR' },
    { key: 5, value: '3BR' },
    { key: 6, value: '4BR' },
];

export const fillDropdownOptions = max => {
    let options = [];
    let i = 1;

    while (i <= max) {
        options.push({ key: i, value: i });
        i++;
    }

    return options;
};

export const findValueByKey = (options, key) => {
    return options.find(item => item.key === key).value || key;
};

export const validateGuestCardData = data => {
    let structure_rules = [
        {
            name: 'prospect',
            type: 'object',
            required: true,
            properties: [
                {
                    name: 'first_name',
                    required: true,
                    type: 'string',
                    maxLength: 50,
                },
                {
                    name: 'last_name',
                    required: true,
                    type: 'string',
                    maxLength: 50,
                },
                {
                    name: 'phone',
                    required: false,
                    type: 'phone',
                    maxLength: 17,
                },
                {
                    name: 'email',
                    required: true,
                    type: 'email',
                    maxLength: 50,
                },
            ],
        },
        {
            name: 'contact',
            required: true,
            type: 'array',
        },
        {
            name: 'referral',
            required: true,
            type: 'string',
            maxLength: 36,
        },
        {
            name: 'coresidents',
            required: false,
            type: 'array-of-objects',
            properties: [
                {
                    name: 'personalInfo',
                    type: 'object',
                    required: true,
                    properties: [
                        {
                            name: 'first_name',
                            required: true,
                            type: 'string',
                            maxLength: 50,
                        },
                        {
                            name: 'last_name',
                            required: true,
                            type: 'string',
                            maxLength: 50,
                        },
                        {
                            name: 'phone',
                            required: false,
                            type: 'phone',
                            maxLength: 17,
                        },
                        {
                            name: 'email',
                            required: true,
                            type: 'email',
                            maxLength: 50,
                        },
                    ],
                },
            ],
        },
        {
            name: 'pets',
            required: false,
            type: 'array-of-objects',
            properties: [
                {
                    name: 'petInfo',
                    type: 'object',
                    required: true,
                    properties: [
                        {
                            name: 'name',
                            required: true,
                            type: 'string',
                            maxLength: 50,
                        },
                        {
                            name: 'breed',
                            required: true,
                            type: 'string',
                            maxLength: 50,
                        },
                        {
                            name: 'type',
                            required: false,
                            type: 'string',
                            maxLength: 50,
                        },
                        {
                            name: 'service_animal',
                            required: true,
                            type: 'boolean',
                        },
                    ],
                },
            ],
        },
        {
            name: 'apartment',
            required: true,
            type: 'object',
            properties: [
                {
                    name: 'preferredLayout',
                    required: false,
                    maxLength: 150,
                    type: 'string',
                },
                {
                    name: 'preferredAmenities',
                    required: false,
                    maxLength: 150,
                    type: 'string',
                },
            ],
        },
    ];

    let checkResults = [];
    structure_rules.forEach(rule => {
        console.log(rule.name, ' => ', rule.type);
        checkResults[rule.name] = checkData(rule, data[rule.name]);
    });
    console.log('checked_results =>', checkResults);
    return checkResults;
};
const checkData = (rule, data) => {
    let result = [];

    var json_data = (rule.type == 'object' || rule.type == 'array-of-objects') && typeof data == 'string' ? JSON.parse(data) : data;
    console.log('data => ', data);

    let phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; // eslint-disable-line
    let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; // eslint-disable-line
    switch (rule.type) {
    case 'string':
        if (typeof json_data == 'string') {
            if (json_data.length <= rule.maxLength) {
                result = {
                    data_name: rule.name,
                    isCorrect: true,
                };
            } else {
                result = {
                    data_name: rule.name,
                    isCorrect: false,
                    message: 'Value exceeds the max characters (' + rule.maxLength + ').',
                };
            }
        }
        break;
    case 'email':
        if (json_data.match(emailRegex)) {
            result = {
                data_name: rule.name,
                isCorrect: true,
            };
        } else {
            result = {
                data_name: rule.name,
                isCorrect: false,
                message: 'Value is not a valid email.',
            };
        }
        break;
    case 'phone':
        if (json_data.match(phoneRegex)) {
            result = {
                data_name: rule.name,
                isCorrect: true,
            };
        } else {
            result = {
                data_name: rule.name,
                isCorrect: false,
                message: 'Value is not a valid email.',
            };
        }
        break;
    case 'object':
        result = {
            type: rule.type,
            name: rule.name,
            properties: [],
        };
        rule.properties.forEach(property => {
            // eslint-disable-line no-mixed-spaces-and-tabs

            result.properties.push(checkData(property, json_data[property.name])); // eslint-disable-line no-mixed-spaces-and-tabs
        }); // eslint-disable-line no-mixed-spaces-and-tabs
        break;
    case 'array-of-objects':
        result = {
            type: rule.type,
            name: rule.name,
            list: [],
        };

        json_data.forEach(item => {
            rule.properties.forEach(property => {
                result.list.push(checkData(property, item[property.name]));
            });
        });
        break;
    case 'object-json':
        result = {
            type: rule.type,
            name: rule.name,
            list: [],
        };
        rule.properties.forEach(property => {
            result.list.push(checkData(property, data[property.name]));
        });

        break;
    case 'boolean':
        if (typeof json_data == 'boolean') {
            result = {
                data_name: rule.name,
                isCorrect: true,
            };
        } else {
            result = {
                data_name: rule.name,
                isCorrect: false,
                message: 'Value is not a valid boolean value.',
            };
        }

        break;
    }
    return result;
};
